export const env = {
  production: false,
  backendUrl:
    'https://customer-consent-e2e.bmwgroup.com/consent-portal/api/v1/',
  cookieName: 'B_CONSENT',
  redirectBackUrl: 'https://customer-consent-e2e.bmwgroup.com',
  isUSHub: false,
  isE2E: true,
  isDev: false,
  clientId: 'b558e823-ade8-4bdb-9655-464694de87a7'
};
